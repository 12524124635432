/**
 * Tracking.js
 * Setups tracking and handles tracking events
 * AWS
 */
import { PAGE_REFERRER } from "./constants";
import TrackingAWS from "./TrackingAWS";
let Tracking = {};

// Initial setup
Tracking.init = userId => {
  const url = "widget.forzafootball.com";
  const isDebug = window.location.hostname.indexOf(url) === -1 ? true : false;

  Tracking.userId = userId;

  if (userId) {
    TrackingAWS.init({
      userId: userId,
      isDebug: isDebug
    });
  }
};

// Track pageview
Tracking.trackPageView = event_attributes => {
  if (!Tracking.userId) return;
  // Handle referrer attributes
  event_attributes = event_attributes || {};
  if (event_attributes.page_type) {
    // Get last page referrer
    const page_referrer = getPageReferrer();
    if (page_referrer && page_referrer !== event_attributes.page_type) {
      event_attributes.referrer = page_referrer;
    }
    // Set new page referrer
    setPageReferrer(event_attributes.page_type);
  }

  event_attributes.is_iframe = inIframe();

  TrackingAWS.recordEvent("ViewLoadedWidget", event_attributes);
};

// Track event
Tracking.trackEvent = (event_name, event_attributes) => {
  if (!Tracking.userId) return;
  TrackingAWS.recordEvent(event_name, event_attributes);
};

Tracking.submitEvents = () => {
  if (!Tracking.userId) return;
  TrackingAWS.submitEvents();
};

const getPageReferrer = () => localStorage.getItem(PAGE_REFERRER) || "";
const setPageReferrer = page => {
  if (page) {
    localStorage.setItem(PAGE_REFERRER, page);
  }
};

const inIframe = () => {
  try {
    return window.self !== window.top ? "1" : "0";
  } catch (e) {
    return "1";
  }
};

export default Tracking;
