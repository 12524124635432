const ViewModelBase = {};

ViewModelBase.getLangFile = async lang => {
  return import(`./translations/${lang}.json`)
    .then(lang => {
      return lang;
    })
    .catch(async e => {
      return await import("./en.json");
    });
};

ViewModelBase.getTranslations = async language => {
  const lang = await ViewModelBase.getLangFile(language);
  return lang.default;
};

export default ViewModelBase;
