import React, { Component } from "react";

import i18n from "./i18n";
import ViewModelBase from "./i18n/ViewModelBase";
import { setMomentLocale } from "./i18n/setMomentLocale";

import { getLang } from "utils/getLang";
import { TRANSLATIONS_NAME } from "utils/constants";
import Utils from "utils/Utils";
import Tracking from "utils/Tracking";

import Routes from "routes/Routes";
import Loading from "components/Loading";

class App extends Component {
  constructor() {
    super();
    this.initSetup();
    this.state = { loading: true };
  }
  initSetup() {
    if (Utils.isTouchDevice()) {
      document.body.classList.add("touch");
      // Set touchstart so we can use :active in css
      document.addEventListener("touchstart", function() {}, true);
    } else {
      document.body.classList.add("no-touch");
    }
  }
  async componentDidMount() {
    const lang = getLang();
    document.body.classList.add("lang-" + lang);
    i18n.addResourceBundle(
      lang,
      TRANSLATIONS_NAME,
      await ViewModelBase.getTranslations(lang)
    );
    i18n.changeLanguage(lang);
    await setMomentLocale(lang);

    Tracking.init(Utils.getUserId());

    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) return <Loading />;

    return <Routes />;
  }
}

export default App;
