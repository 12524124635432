import React from "react";
import "./Loading.scss";

const Loading = () => {
  return (
    <div className="Loading">
      <div className="Loading-inner Loading-inner1" />
      <div className="Loading-inner Loading-inner2" />
    </div>
  );
};

export default Loading;
