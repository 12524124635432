/**
 * i18n.js
 * Init i18n translations
 */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  .init({
    resources: {}, // Loaded from App.js
    lowerCaseLng: true,
    // debug: true,

    fallbackLng: "en",
    lng: "en",

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // "." we do not use keys with dots: Home.welcome

    interpolation: {
      escapeValue: false, // not needed for react
      formatSeparator: ","
    },

    react: {
      wait: true
    }
  });

export default i18n;
