export const getLang = () => {
  let lang =
    window.navigator.language ||
    (window.navigator.languages ? window.navigator.languages[0] : null) ||
    window.navigator.userLanguage;

  if (lang.indexOf("-") !== -1) {
    lang = lang.split("-")[0];
  }
  if (lang.indexOf("_") !== -1) {
    lang = lang.split("_")[0];
  }
  return lang;
};
