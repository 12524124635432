/* global DocumentTouch */
import { AUTH_TOKEN } from "./constants";

let Utils = {};

Utils.getUserId = () => localStorage.getItem(AUTH_TOKEN) || setupUserId();

const setupUserId = () => {
  const userId = createRandomUserId();
  localStorage.setItem(AUTH_TOKEN, userId);
  return userId;
};

const createRandomUserId = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxffr_web".replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * sortPlayers
 * @param { Array } players – unsorted array
 * @return { Array } sorted array
 */
Utils.sortPlayers = players => {
  // Check if null or empty array
  if (!players || !players.length) {
    return players;
  }

  let withShirtNumber = [];
  let withoutShirtNumber = [];
  players.map(player => {
    if (typeof player.shirtNumber === "number") {
      withShirtNumber.push(player);
    } else {
      withoutShirtNumber.push(player);
    }
    return false;
  });

  // Sort by shirtNumber
  withShirtNumber = withShirtNumber.sort((p1, p2) => {
    return p1.shirtNumber - p2.shirtNumber;
  });

  // Sort by firstName
  withoutShirtNumber = withoutShirtNumber.sort((p1, p2) => {
    if (p1.firstName) {
      // Player list
      try {
        return ("" + p1.firstName.toLowerCase()).localeCompare(
          p2.firstName.toLowerCase()
        );
      } catch (e) {
        return -1;
      }
    } else {
      // Lineup Player list
      try {
        return ("" + p1.player.firstName.toLowerCase()).localeCompare(
          p2.player.firstName.toLowerCase()
        );
      } catch (e) {
        return -1;
      }
    }
  });
  // Return merged array
  return withShirtNumber.concat(withoutShirtNumber);
};

/**
 * sortPlayersByPosition
 * @param { Array } players – unsorted array
 * @return { Object } sorted by position
 */
Utils.sortPlayersByPosition = players => {
  // Check if null or empty array
  if (!players || !players.length) {
    return players;
  }

  const positions = {};
  players.map(player => {
    if (player.position) {
      positions[player.position] = positions[player.position] || [];
      positions[player.position].push(player);
    } else {
      positions["unknown"] = positions["unknown"] || [];
      positions["unknown"].push(player);
    }
    return false;
  });

  // Sort by shirtNumber or firstName
  for (const key in positions) {
    if (positions.hasOwnProperty(key)) {
      positions[key] = Utils.sortPlayers(positions[key]);
    }
  }

  return positions;
};

/**
 * sortEvents - (eventlist)
 * @param { Array } events – unsorted array
 * @return { Array } sorted array
 */
Utils.sortEvents = events => {
  let filterEvents = events;
  // Reverse array one time to get latest event first
  if (filterEvents.length && filterEvents[0].type === "MatchStart") {
    filterEvents = filterEvents.reverse();
  }

  // Sort by period
  const periodOrder = [
    "PENALTY_SHOOTOUT",
    "EXTRA_SECOND_HALF",
    "EXTRA_FIRST_HALF",
    "SECOND_HALF",
    "FIRST_HALF"
  ];
  filterEvents = filterEvents.sort((e1, e2) => {
    return periodOrder.indexOf(e1.period) - periodOrder.indexOf(e2.period);
  });

  // Sort by minute
  filterEvents = events.sort((e1, e2) => {
    if (e1.period === e2.period) {
      return e2.minute - e1.minute;
    }
    return 0;
  });
  // Sort by added time
  filterEvents = filterEvents.sort((e1, e2) => {
    if (e1.period === e2.period) {
      if (e1.minute === e2.minute) {
        return e2.addedMinute - e1.addedMinute;
      }
    }
    return 0;
  });
  // Make sure pause, await and stop events is last in the period
  filterEvents = filterEvents.sort((e1, e2) => {
    if (e1.period !== e2.period) {
      return 0;
    }
    if (
      e1.type === "MatchPause" ||
      e1.type === "MatchStop" ||
      e1.type === "MatchPauseExtraTime" ||
      e1.type === "ExtraTimeStop" ||
      e1.type === "MatchAwaitPenaltyShootout" ||
      e1.type === "PenaltyShootoutStop"
    ) {
      return -1;
    }
    if (
      e2.type === "MatchPause" ||
      e2.type === "MatchStop" ||
      e2.type === "MatchPauseExtraTime" ||
      e2.type === "ExtraTimeStop" ||
      e2.type === "MatchAwaitPenaltyShootout" ||
      e2.type === "PenaltyShootoutStop"
    ) {
      return 1;
    }
    return 0;
  });
  return filterEvents;
};

/**
 * dateToTimestamp
 * @param { String } dateString – "yyyy-mm-ddThh:mm:ssZ"
 * @return { Number } timestamp
 */
Utils.dateToTimestamp = dateString => {
  if (typeof dateString === "string") {
    return new Date(dateString).getTime();
  }
  return null;
};

/**
 * dateToMinutes
 * @param { String } periodStart – "yyyy-mm-ddThh:mm:ssZ"
 * @param { String } now – "yyyy-mm-ddThh:mm:ssZ"
 * @return { Number } match minute
 */
Utils.dateToMinutes = (periodStart, now) => {
  const timestampStart = Utils.dateToTimestamp(periodStart);
  const timestampNow = Utils.dateToTimestamp(now);
  if (timestampStart) {
    const ms = ((timestampNow - timestampStart) / 1000).toFixed(0) * 1000;
    const seconds = parseInt(ms, 10) / 1000;
    let minutes = parseInt(seconds / 60, 10);
    // Always add one football minute
    minutes += 1;
    return minutes;
  }
  return 0;
};

/**
 * isMatchPlaying
 * @param { String } state – the match state
 * @return { Boolean }
 */
Utils.isMatchPlaying = data => {
  switch (data.state) {
    case "FIRST_HALF":
    case "SECOND_HALF":
    case "EXTRA_FIRST_HALF":
    case "EXTRA_SECOND_HALF":
      return true;
    default:
      return false;
  }
};

/**
 * isMatchStarted
 * @param { Object } match – match object
 * @return { Boolean }
 */
Utils.isMatchStarted = match => {
  if (match && match.currentPeriodStart) {
    return true;
  }
  return false;
};

/**
 * scoreToArray
 * @param { String } score – "2:2"
 * @return { Array } [2,2]
 */
Utils.scoreToArray = score => {
  try {
    const splitScore = score.split(":");
    return [parseInt(splitScore[0], 10), parseInt(splitScore[1], 10)];
  } catch (e) {
    return [0, 0];
  }
};

/**
 * scoreToText
 * @param { String } score – "2:2"
 * @return { String } 2-2
 */
Utils.scoreToText = score => {
  try {
    const splitScore = score.split(":");
    return splitScore[0] + "-" + splitScore[1];
  } catch (e) {
    return "0-0";
  }
};

Utils.isTouchDevice = () => {
  var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  var mq = function(query) {
    return window.matchMedia(query).matches;
  };

  if (
    "ontouchstart" in window ||
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true;
  }

  // https://git.io/vznFH
  var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
  return mq(query);
};

export default Utils;
