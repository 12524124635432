/**
 * Routes.js
 * Handle all routes
 */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";

import Loading from "components/Loading";

// Dynamic import route
const getComponent = name => {
  return import("routes/" + name);
};
// Dynamic load route
// https://github.com/jamiebuilds/react-loadable
const loadablePage = name => {
  return Loadable({
    loader: () => getComponent(name),
    loading: props => {
      if (props.error) {
        return <h1>Error loading page</h1>;
      } else if (props.pastDelay) {
        // Show loader after 200ms
        return <Loading />;
      }
      return null;
    }
  });
};

const Routes = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/match/:id" component={loadablePage("match/Match")} />
          <Route path="/team/:id" component={loadablePage("team/Team")} />
          <Route
            path="/tournament/:id"
            component={loadablePage("tournament/Tournament")}
          />
          <Route path="*" component={loadablePage("error/Error")} />
        </Switch>
      </div>
    </Router>
  );
};

export default Routes;
