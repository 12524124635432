import moment from "moment";

export const setMomentLocale = async lang => {
  return import(`moment/locale/${lang}`)
    .then(locale => {
      moment.updateLocale(lang, locale);
    })
    .catch(e => {
      return;
    });
};
