/* Schema
 * Based on: https://github.com/forzafootball/log-schema/blob/master/logschema/schemas_clubsourcing.py
 * event_name: { attributes }
 */

let TrackingLogSchema = {};

TrackingLogSchema.schema = {
  ViewLoadedWidget: {
    page_type: { type: "string", required: true },
    type_id: { type: "string", required: true },
    referrer: { type: "string", required: false },
    is_iframe: { type: "string", required: true }
  }
};

export default TrackingLogSchema;
