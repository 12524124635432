import "aws-sdk-mobile-analytics";
import packageJson from "../../package.json";
import TrackingLogSchema from "./TrackingLogSchema";
let TrackingAWS = {};

TrackingAWS.init = settings => {
  TrackingAWS.isDebug = settings.isDebug;

  // Skip setup if debug
  if (TrackingAWS.isDebug) return;

  // Is android app (or maybe ios app in future)
  let isApp = "0";
  if (window.location.search.indexOf("isApp=true") !== -1) {
    isApp = "1";
  }

  // Store user agent string of browser
  const userAgent = window.navigator && window.navigator.userAgent;

  window.AWS.config.region = "us-east-1";
  window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:e47e69fd-afbd-4d96-8462-cd0165c94a8c"
  });

  const options = {
    appId: "ca3796edf5a54a05a7b2a42da65fe7e5",
    appTitle: "Widget",
    appVersionName: packageJson.version,
    globalAttributes: {
      user_id: settings.userId,
      user_agent: userAgent,
      is_app: isApp,
      is_debug: TrackingAWS.isDebug ? "1" : "0"
    }
  };
  TrackingAWS.mobileAnalyticsClient = new window.AMA.Manager(options);
};

TrackingAWS.recordEvent = (event_name, event_attributes) => {
  if (TrackingAWS.isEventValid(event_name, event_attributes)) {
    if (TrackingAWS.isDebug) {
      console.log("Tracking:", event_name, event_attributes);
    } else {
      //console.log("Post live", event_name, event_attributes);
      TrackingAWS.mobileAnalyticsClient.recordEvent(
        event_name,
        event_attributes
      );
    }
  }
};

TrackingAWS.isEventValid = (event_name, event_attributes) => {
  if (!event_name || !event_attributes) {
    console.error(
      "Tracking - Missing event_name or event_attributes!\n",
      "event_name:",
      event_name,
      "\nevent_attributes:",
      event_attributes
    );
    return;
  }
  const { schema } = TrackingLogSchema;
  const schemaAttributes = schema[event_name];
  if (schemaAttributes) {
    let error = false;
    for (let key in schemaAttributes) {
      if (schemaAttributes[key].required) {
        if (schemaAttributes[key].type !== typeof event_attributes[key]) {
          error = true;
        }
      }
    }
    let errorUnknownAttributes = false;
    for (let key in event_attributes) {
      if (!schemaAttributes[key]) {
        error = true;
        errorUnknownAttributes = true;
      }
      if (typeof event_attributes[key] !== schemaAttributes[key].type) {
        error = true;
      }
    }

    if (!error) {
      return true;
    } else {
      let t = "Tracking - Missing required attributes for " + event_name + "\n";
      if (errorUnknownAttributes) {
        t = "Tracking - Unknown event_attributes for " + event_name + "\n";
      }
      console.error(
        t,
        "event_attributes: ",
        event_attributes,
        "\nschema_attributes: ",
        schemaAttributes
      );
      // Track errors
      if (typeof event_name === "string") {
        TrackingAWS.recordEvent("Error", {
          page: "TrackingLogSchema",
          message: event_name
        });
      }
    }
  } else {
    console.error("Tracking - schema_name not found: " + event_name);
  }
  return false;
};

// Force send events
TrackingAWS.submitEvents = () => {
  TrackingAWS.mobileAnalyticsClient.submitEvents();
};

export default TrackingAWS;
