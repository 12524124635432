import "react-app-polyfill/ie11"; // https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import React from "react";
import ReactDOM from "react-dom";
import "assets/styles/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// TODO: activate and test
serviceWorker.unregister();
